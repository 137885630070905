import styled from '@emotion/styled';
import { NextPage } from 'next';
import Image from 'next/image';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Title = styled.p`
  color: var(--Primary-Gray-Primary-Gray, #222);
  text-align: center;

  font-family: Pretendard;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px; /* 150% */
  letter-spacing: -0.56px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  max-width: 1920px;
`;

const Description = styled.p`
  color: var(--GrayScale-Gray-7, #535353);
  text-align: center;
  /* Body/Paragraph 2 */
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px; /* 144.444% */
  letter-spacing: -0.36px;
`;

const TimeWrapper = styled.div`
  display: inline-flex;
  padding: 40px;
  justify-content: center;
  align-items: center;

  border-radius: 8px;
  background: var(--GrayScale-Gray-1, #f6f6f6);
`;

const TimeText = styled.p`
  color: var(--Primary-Gray-Primary-Gray, #222);
  text-align: center;
  /* Title/Subheading 2 */
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 140% */
  letter-spacing: -0.4px;
`;

const ConstructionPage: NextPage = () => {
  return (
    <Container>
      <Wrapper>
        <Image src="/assets/images/construction.jpg" alt="logo" width={100} height={200} />
        <DescriptionWrapper>
          <Title>서비스 업데이트 및 중입니다.</Title>
          <Description>
            안녕하세요. 스틸보소 입니다.
            <br />
            점검 시간 동안 서비스 이용이 제한되는 점 양해 부탁드립니다.
            <br />
            스틸보소를 방문해 주신 이용자분들의 관심에 깊은 감사의 말씀을 전하며, <br />
            보다 개선된 서비스로 찾아뵙겠습니다. 감사합니다.
          </Description>
        </DescriptionWrapper>
        <TimeWrapper>
          <TimeText>
            점검일시: 2025년 4월 7일 20시 00분 ~ 2025년 4월 8일 02시 00분(약 6시간 소요 예정) <br />
            작업내용: 스틸보소 서비스 개선을 위한 업데이트
          </TimeText>
        </TimeWrapper>
      </Wrapper>
    </Container>
  );
};

export default ConstructionPage;
